<template>
  <v-card>
    <v-card-title><h4>Roles</h4></v-card-title>
    <v-data-table
      :headers="headers"
      :items="items.items"
      :server-items-length="items.meta.total"
      :loading="loading"
      :options.sync="options"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                <v-icon dense small>{{ icons.mdiPlus }}</v-icon
                >&nbsp;New Role
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="edit">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="Name"
                          required
                          v-model="editedItem.name"
                          :error-messages="nameErrors"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-combobox
                          v-model="editedItem.permissions"
                          :items="permissions"
                          label="Permissions"
                          multiple
                          chips
                          required
                          :error-messages="permissionErrors"
                          ><template v-slot:selection="data">
                            <v-chip
                              :key="JSON.stringify(data.item)"
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              :disabled="data.disabled"
                              @click:close="data.parent.selectItem(data.item)"
                              :color="getColor(data.item)"
                              dark
                            >
                              {{ data.item }}
                            </v-chip>
                          </template>
                        </v-combobox>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="save"> Save </v-btn>
                <v-btn color="secondary" outlined @click="close">
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="400px">
            <v-card>
              <v-card-title class="text-h5 text-break text-center"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" outlined @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="primary" @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.permissions="{ item }">
        <v-chip
          v-for="permission in item.permissions"
          :key="permission.name"
          dark
          :color="getColor(permission.name)"
        >
          {{ permission.name }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          {{ icons.mdiPencil }}
        </v-icon>
        <v-icon small @click="deleteItem(item)"> {{ icons.mdiDelete }} </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="getDataFromApi"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { mdiPencil, mdiPlus, mdiDelete } from "@mdi/js"
import { mapActions, mapState } from "vuex"
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"

export default {
  mixins: [validationMixin],
  validations() {
    if (this.editedIndex == -1) {
      return {
        editedItem: {
          name: { required },
          permissions: { required },
        },
      }
    } else {
      return {
        editedItem: {
          name: { required },
          permissions: { required },
        },
      }
    }
  },
  data() {
    return {
      loading: true,
      total: 0,
      options: {},

      dialog: false,
      dialogDelete: false,

      editedIndex: -1,
      editedItem: {
        id: -1,
        name: "",
        permissions: [],
      },
      defaultItem: {
        id: -1,
        name: "",
        permissions: [],
      },
      icons: { mdiPencil, mdiPlus, mdiDelete },
    }
  },
  computed: {
    headers() {
      return [
        { text: "Name", value: "name" },
        { text: "Permissions", sortable: false, value: "permissions" },
        { text: "Actions", sortable: false, value: "actions", align: "center" },
      ]
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Role" : "Edit Role"
    },
    permissions() {
      return this.permissionObjects.items.map(item => item.name)
    },
    nameErrors() {
      const errors = []
      if (!this.$v.editedItem.name.$dirty) return errors
      !this.$v.editedItem.name.required && errors.push("Name is required.")
      return errors
    },
    permissionErrors() {
      const errors = []
      if (!this.$v.editedItem.permissions.$dirty) return errors
      !this.$v.editedItem.permissions.required &&
        errors.push("You should have a least a permission.")
      return errors
    },
    ...mapState({
      items: state => state.users.roles,
      permissionObjects: state => state.users.permissions,
    }),
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  methods: {
    getColor(permission) {
      if (permission.indexOf("manage") != -1) {
        return "error"
      }
      if (
        permission.indexOf("edit") != -1 ||
        permission.indexOf("create") != -1
      ) {
        return "success"
      }
      return "secondary"
    },

    getDataFromApi() {
      this.loading = true
      this.$nextTick(() => {
        const start = (this.options.page - 1) * this.options.itemsPerPage
        this.loadRoles({ start: start, limit: this.options.itemsPerPage }).then(
          () => {
            this.loading = false
          }
        )
      })
    },

    editItem(item) {
      this.editedIndex = this.items.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.permissions = this.editedItem.permissions.map(
        item => item.name
      )
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.items.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.loading = true
      this.deleteRole(this.editedItem.id).then(() => {
        this.loading = false
      })
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$v.$reset()
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      this.$v.$touch()
      if (!this.$v.$anyError) {
        this.loading = true
        if (this.editedIndex > -1) {
          this.editRole(this.editedItem).then(() => {
            this.getDataFromApi()
          })
        } else {
          this.addRole(this.editedItem).then(() => {
            this.getDataFromApi()
          })
        }
        this.close()
      }
    },
    ...mapActions("users", [
      "loadRoles",
      "loadPermissions",
      "addRole",
      "editRole",
      "deleteRole",
    ]),
  },
  mounted() {
    this.loadPermissions({ limit: -1 })
  },
}
</script>
